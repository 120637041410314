export default {
  NAVIGATION: {
    SERVICES: 'Dienste',
    SERVICE_SUBMISSIONS: 'Dienst Submissions',
    ALL_INCLUSIVE_SERVICES: 'Rundum Sorglos Dienste',
    TIER_SERVICES: 'Dienste nach Dringlichkeit',
    SERVICEAREAS: 'Dienstgebiete',
    USERS: 'Nutzer',
    INSURANCES: 'Versicherungen',
    ACCOUNTING: 'Buchhaltung',
    REPORTING: 'Reporting',
  },
  TAB_LABELS: {
    OPERATOR_SERVICES: 'Dienstverwaltung',
    OPERATOR_SERVICESUBMISSIONS: 'Dienst Submissions',
    OPERATOR_ALLINCLUSIVE_SERVICES: 'Rundum-Sorglos Dienste',
    OPERATOR_TIER_SERVICES: 'Dienste nach Dringlichkeit',
    OPERATOR_SERVICEAREAS: 'Dienstgebiete',
    OPERATOR_SINGLE_SERVICEAREA: 'Dienstgebiet',
    OPERATOR_USERS: 'Nutzerverwaltung',
    OPERATOR_INSURANCES: 'Versicherungen',
    OPERATOR_ACCOUNTING: 'Buchhaltung',
    OPERATOR_REPORTING: 'Reporting',
    OPERATOR_SINGLE_USER: 'Nutzer',
    OPERATOR_SINGLE_SERVICE: 'Dienst',
  },
  CLOSE_TAB: 'Tab schließen',
  TABLE: {
    ROWS_PER_PAGE: 'Zeilen pro Seite',
    NO_DATA: 'Keine Daten geladen',
    SEPARATOR: 'von',
  },
  CONFIRM: {
    YES: 'Ja',
    NO: 'Nein',
  }
};
