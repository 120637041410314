import { DocumentNode } from 'graphql/language';
import gql from 'graphql-tag';
import {
  ADDRESS_FRAGMENT,
  BANKACCOUNT_FRAGMENT,
  DEBTOR_FRAGMENT,
  DOCTOR_FRAGMENT,
  MESSAGE_FRAGMENT,
  SERVICE_FRAGMENT_GUEST,
  SERVICE_FRAGMENT_ASSIGNED_SUBSTITUTE,
  SERVICE_FRAGMENT_PUBLIC,
  SERVICE_FRAGMENT_SUBSTITUTE,
  SERVICEAREA_FRAGMENT,
  BOOKMARK_FRAGMENT,
  USER_FRAGMENT,
  SERVICE_FRAGMENT_PARTNER,
  APPLICATION_FRAGMENT,
  APPLICANTS_FRAGMENT,
  SERVICE_FRAGMENT_PARTNER_URL,
  SERVICE_FRAGMENT_PUBLIC_MAX,
  SUBSTITUTE_FRAGMENT,
  SERVICE_MANAGEMENT_FRAGMENT_PARTNER,
  SERVICE_MANAGEMENT_FRAGMENT_SUBSTITUTE,
  SERVICE_FRAGMENT_SERVICE_DETAIL_PAGE,
  SUBMISSIONS_FRAGMENT,
} from '@/data/gql/fragments';

export const LIST_SERVICES_GUEST: DocumentNode = gql`
  query GuestListServices(
    $serviceID: UUID
    $start: DateTime
    $end: DateTime
    $minRegularFee: Float
    $minHolidayFee: Float
    $states: [String!]
    $types: [UserServiceType!]
    $orderBy: String
    $sortOrder: String
    $take: Int
    $skip: Int
    $cursor: UUID
  ) {
    guestListServicesCount(
      start: $start
      end: $end
      states: $states
      types: $types
    )
    guestListServices(
      serviceID: $serviceID
      start: $start
      end: $end
      minRegularFee: $minRegularFee
      minHolidayFee: $minHolidayFee
      states: $states
      types: $types
      take: $take
      skip: $skip
      orderBy: $orderBy
      sortOrder: $sortOrder
      cursor: $cursor
    ) {
      ... on mobile_service {
        details {
          ...serviceFieldsGuest
        }
        serviceArea {
          comment
          coordinates
        }
      }
      ... on office_service {
        details {
          ...serviceFieldsGuest
        }
        serviceArea {
          comment
          address {
            ...addressFields
          }
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_GUEST}
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const ROLES_QUERY: DocumentNode = gql`
  query {
    profile {
      roles
    }
  }
`;

export const PROFILE_QUERY: DocumentNode = gql`
  query {
    profile {
      ...userFields
      roles
      authorization {
        queries
        mutations
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const PROFILE_QUERY_PARTNER: DocumentNode = gql`
  query {
    profile {
      ...userFields
      roles
      authorization {
        queries
        mutations
      }
      messages {
        ...publicMessageFields
      }
      institutions {
        id
        bsnr
        name
        type
        verified
        kvSeats
        address {
          ...addressFields
        }
        debtor {
          ...debtorFields
        }
        establishments {
          id
          bsnr
          name
          submissions {
            content
          }
          doctors {
            ...doctorFields
          }
          billingAddress {
            ...addressFields
          }
          serviceArea {
            ...serviceareaFields
          }
          services {
            ... on mobile_service {
              serviceArea {
                id
                name
                shorthandName
                coordinates
                comment
              }
              details {
                ...serviceFieldsPartner
              }
            }
            ... on office_service {
              serviceArea {
                id
                name
                shorthandName
                comment
                address {
                  ...addressFields
                }
              }
              details {
                ...serviceFieldsPartner
              }
            }
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${MESSAGE_FRAGMENT}
  ${BANKACCOUNT_FRAGMENT}
  ${SERVICEAREA_FRAGMENT}
  ${SERVICE_FRAGMENT_PARTNER}
  ${DOCTOR_FRAGMENT}
  ${DEBTOR_FRAGMENT}
`;

export const PROFILE_QUERY_PARTNER_READ_MSGS: DocumentNode = gql`
  query {
    profile {
      ...userFields
      roles
      authorization {
        queries
        mutations
      }
      messages(unread: false) {
        ...publicMessageFields
      }
    }
  }
  ${USER_FRAGMENT}
  ${MESSAGE_FRAGMENT}
`;

export const PROFILE_QUERY_PARTNER_1: DocumentNode = gql`
  query {
    profile {
      ...userFields
      roles
      authorization {
        queries
        mutations
      }
      messages(unread: true) {
        ...publicMessageFields
      }
    }
  }
  ${USER_FRAGMENT}
  ${MESSAGE_FRAGMENT}
`;

export const PROFILE_QUERY_PARTNER_2: DocumentNode = gql`
  query {
    profile {
      institutions {
        __typename
        id
        bsnr
        name
        type
        verified
        kvSeats
        address {
          ...addressFields
        }
        debtor {
          ...debtorFields
        }
      }
    }
  }
  ${DEBTOR_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${BANKACCOUNT_FRAGMENT}
`;

export const PROFILE_QUERY_PARTNER_3: DocumentNode = gql`
  query {
    profile {
      institutions {
        id
        establishments {
          # __typename
          id

          bsnr
          name
          submissions {
            # __typename
            # id
            content
          }
          doctors {
            ...doctorFields
          }
          billingAddress {
            ...addressFields
          }
          serviceArea {
            ...serviceareaFields
            # __typename
            # id
            # mobileServices {
            #   __typename
            #   id
            # }
            # officeServices {
            #   __typename
            #   id
            #   address {
            #     __typename
            #     id
            #   }
            # }
          }
        }
      }
    }
  }
  ${ADDRESS_FRAGMENT}
  ${DOCTOR_FRAGMENT}
  ${SERVICEAREA_FRAGMENT}
`;

export const PROFILE_QUERY_PARTNER_4: DocumentNode = gql`
  query {
    profile {
      institutions {
        # __typename
        id
        establishments {
          #__typename
          id
          services {
            __typename
            # id
            ... on mobile_service {
              serviceArea {
                id
                name
                shorthandName
                coordinates
                comment
              }
              details {
                ...serviceFieldsPartner
              }
            }
            ... on office_service {
              serviceArea {
                id
                name
                shorthandName
                comment
                address {
                  ...addressFields
                }
              }
              details {
                ...serviceFieldsPartner
              }
            }
          }
        }
        __typename
      }
      __typename
    }
  }
  ${ADDRESS_FRAGMENT}
  ${SERVICEAREA_FRAGMENT}
  fragment serviceFieldsPartner on service {
    id
    createdBy
    published
    concluded
    start
    end
    kind
    comment
    orderNumber
    stateInsuredPatientCount
    privatePatientCount
    necropsyCount
    consultationViaTelephoneCount
    partnerConfirmedContractOn
    substituteConfirmedContractOn
    countsConfirmedOn
    establishmentDoctor {
      id
      # ...doctorFields
    }
    establishment {
      id
      # billingAddress {
      #   ...addressFields
      # }
    }
    serviceArea {
      # id
      ...serviceareaFields
    }
    regularFeeRange {
      min
      max
      current
    }
    holidayFeeRange {
      min
      max
      current
    }
    substitute {
      details {
        license {
          url
        }
        user {
          email
          title
          firstName
          lastName
          birthDate
          mobile1
          mobile2
          fax
          phone
        }
        address {
          city
          street
          country
          houseNr
          zipCode
          state
        }
      }
      liabilityInsurance {
        url
      }
      specializations {
        specialization
        certificate {
          url
        }
      }
    }
  }
`;

export const SERVICES_QUERY_PARTNER: DocumentNode = gql`
  query {
    profile {
      institutions {
        id
        bsnr
        name
        type
        verified
        kvSeats
        establishments {
          id
          bsnr
          name
          services {
            ... on mobile_service {
              serviceArea {
                id
                name
                shorthandName
                coordinates
                comment
              }
              details {
                ...serviceFieldsPartner
              }
            }
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${ADDRESS_FRAGMENT}
  ${SERVICE_FRAGMENT_PARTNER}
  ${SERVICEAREA_FRAGMENT}
  ${DOCTOR_FRAGMENT}
`;

export const SERVICE_APPLICANTS_QUERY: DocumentNode = gql`
  query listServices($serviceID: UUID!) {
    listServices(serviceID: $serviceID) {
      ... on mobile_service {
        details {
          ...applicants
          __typename
        }
        __typename
      }
      ... on office_service {
        details {
          ...applicants
          __typename
        }
        __typename
      }
    }
    __typename
  }
  ${APPLICANTS_FRAGMENT}
`;

export const PROFILE_QUERY_SUBSTITUTE: DocumentNode = gql`
  query {
    profile {
      ...userFields
      roles
      authorization {
        queries
        mutations
      }
      messages {
        ...publicMessageFields
      }
      substitute {
        id
        taxNumber
        trainingConfirmed
        verified
        debtor {
          ...debtorFields
        }
        bankAccount {
          ...bankaccountFields
        }
        service_bookmarks {
          ... on mobile_service {
            serviceArea {
              name
              shorthandName
              comment
              __typename
            }
            details {
              ...publicServiceFields
              __typename
            }
            __typename
          }
          ... on office_service {
            serviceArea {
              name
              shorthandName
              comment
              __typename
            }
            details {
              ...publicServiceFields
              __typename
            }
            __typename
          }
          __typename
        }
        applications {
          ... on mobile_service {
            serviceArea {
              name
              shorthandName
              comment
            }
            details {
              ...publicServiceFields
            }
          }
          ... on office_service {
            serviceArea {
              name
              shorthandName
              comment
            }
            details {
              ...publicServiceFields
            }
          }
        }
        assignments {
          ... on mobile_service {
            serviceArea {
              name
              shorthandName
              comment
              confidentialComment
            }
            details {
              ...assignedServiceFields
            }
          }
          ... on office_service {
            serviceArea {
              name
              shorthandName
              comment
              confidentialComment
              address {
                city
                street
                country
                houseNr
                zipCode
                state
              }
            }
            details {
              ...assignedServiceFields
            }
          }
        }
        address {
          ...addressFields
        }
        license {
          url
          uploadedOn
        }
        specializations {
          id
          specialization
          certificate {
            url
            uploadedOn
          }
        }
        liabilityInsurances {
          id
          validFrom
          validTo
          insuranceName
          insuranceNumber
          document {
            url
            uploadedOn
          }
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${MESSAGE_FRAGMENT}
  ${BANKACCOUNT_FRAGMENT}
  ${SERVICEAREA_FRAGMENT}
  ${SERVICE_FRAGMENT_PUBLIC}
  ${SERVICE_FRAGMENT_ASSIGNED_SUBSTITUTE}
  ${DEBTOR_FRAGMENT}
`;

export const PROFILE_QUERY_SUBSTITUTE_READ_MSGS: DocumentNode = gql`
  query {
    profile {
      ...userFields
      roles
      authorization {
        queries
        mutations
      }
      messages(unread: false) {
        ...publicMessageFields
      }
    }
  }
  ${USER_FRAGMENT}
  ${MESSAGE_FRAGMENT}
`;

export const PROFILE_QUERY_SUBSTITUTE_1: DocumentNode = gql`
  query {
    profile {
      ...userFields
      roles
      authorization {
        queries
        mutations
      }
      messages(unread: true) {
        ...publicMessageFields
      }
    }
  }
  ${USER_FRAGMENT}
  ${MESSAGE_FRAGMENT}
`;

export const QUERY_SUBSTITUTE: DocumentNode = gql`
  query {
    profile {
      substitute {
        ...substituteFields
        debtor {
          ...debtorFields
          __typename
        }
        bankAccount {
          ...bankaccountFields
          __typename
        }
        address {
          ...addressFields
          __typename
        }
        license {
          url
          uploadedOn
          __typename
        }
        specializations {
          id
          specialization
          verified
          certificate {
            id
            url
            filename
            uploadedOn
            __typename
          }
          __typename
        }
        liabilityInsurances {
          id
          validFrom
          validTo
          insuranceName
          insuranceNumber
          document {
            url
            uploadedOn
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${SUBSTITUTE_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${BANKACCOUNT_FRAGMENT}
  ${DEBTOR_FRAGMENT}
`;

export const QUERY_SUBSTITUTE_INSURANCES: DocumentNode = gql`
  query {
    profile {
      substitute {
        liabilityInsurances {
          id
          validFrom
          validTo
          insuranceName
          insuranceNumber
          document {
            url
            uploadedOn
            __typename
          }
        }
      }
    }
  }
`;

export const QUERY_BOOKMARKS_SUBSTITUTE: DocumentNode = gql`
  query {
    profile {
      substitute {
        service_bookmarks {
          ... on mobile_service {
            serviceArea {
              name
              shorthandName
              comment
              coordinates
              __typename
            }
            details {
              ...bookmarkFields
              __typename
            }
            __typename
          }
          ... on office_service {
            serviceArea {
              name
              shorthandName
              comment
              address {
                ...addressFields
              }
              __typename
            }
            details {
              ...bookmarkFields
              __typename
            }
            __typename
          }
          __typename
        }
      }
    }
  }
  ${ADDRESS_FRAGMENT}
  ${BOOKMARK_FRAGMENT}
`;

export const QUERY_APPLICATIONS_SUBSTITUTE: DocumentNode = gql`
  query {
    profile {
      substitute {
        applications {
          ... on mobile_service {
            serviceArea {
              name
              shorthandName
              comment
              __typename
            }
            details {
              ...applicationFields
              __typename
            }
            __typename
          }
          ... on office_service {
            serviceArea {
              name
              shorthandName
              comment
              __typename
            }
            details {
              ...applicationFields
              __typename
            }
            __typename
          }
          __typename
        }
      }
    }
  }
  ${APPLICATION_FRAGMENT}
`;

export const QUERY_ASSIGNMENTS_SUBSTITUTE: DocumentNode = gql`
  query {
    profile {
      substitute {
        assignments {
          ... on mobile_service {
            serviceArea {
              name
              shorthandName
              comment
              confidentialComment
              __typename
            }
            details {
              ...assignedServiceFields
              __typename
            }
            __typename
          }
          ... on office_service {
            serviceArea {
              name
              shorthandName
              comment
              confidentialComment
              address {
                city
                street
                country
                houseNr
                zipCode
                state
                __typename
              }
              __typename
            }
            details {
              ...assignedServiceFields
              __typename
            }
            __typename
          }
          __typename
        }
      }
    }
  }
  ${ADDRESS_FRAGMENT}
  ${SERVICEAREA_FRAGMENT}
  ${SERVICE_FRAGMENT_ASSIGNED_SUBSTITUTE}
`;

export const QUERY_SERVICE_SERVICEMANAGEMENT_PARTNER = gql `
  query getServiceServiceManagementPartner(
    $serviceID: UUID
  ){
    listServices(
      serviceID: $serviceID)
     {
      ... on mobile_service {
        __typename
        details {
          ...serviceManagementFields
        }
      }
      ... on office_service {
        __typename
        details {
          ...serviceManagementFields
        }
      }
     }
  }
  ${SERVICE_MANAGEMENT_FRAGMENT_PARTNER}
  `;

export const QUERY_SERVICE_SERVICEMANAGEMENT_SUBSTITUTE = gql `
  query getServiceServiceManagementPartner(
    $serviceID: UUID
  ){
    listServices(
      serviceID: $serviceID)
     {
      ... on mobile_service {
        __typename
        details {
          ...serviceManagementFields
        }
      }
      ... on office_service {
        __typename
        details {
          ...serviceManagementFields
        }
      }
     }
  }
  ${SERVICE_MANAGEMENT_FRAGMENT_SUBSTITUTE}
  `;

export const LIST_SERVICES_SUBSTITUTE: DocumentNode = gql`
  query ListServices(
    $serviceID: UUID
    $start: DateTime
    $end: DateTime
    $minRegularFee: Float
    $minHolidayFee: Float
    $states: [String!]
    $types: [UserServiceType!]
  ) {
    listServices(
      serviceID: $serviceID
      start: $start
      end: $end
      minRegularFee: $minRegularFee
      minHolidayFee: $minHolidayFee
      states: $states
      types: $types
    ) {
      ... on mobile_service {
        serviceArea {
          name
          shorthandName
          comment
          confidentialComment
        }
        details {
          ...serviceFieldsSubstitute
        }
      }
      ... on office_service {
        serviceArea {
          name
          shorthandName
          comment
          confidentialComment
          address {
            city
            street
            country
            houseNr
            zipCode
            state
          }
        }
        details {
          ...serviceFieldsSubstitute
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_SUBSTITUTE}
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${SERVICE_FRAGMENT_SUBSTITUTE}
`;

export const LIST_SERVICES_ASSIGNED_SUBSTITUTE: DocumentNode = gql`
  query ListServices(
    $serviceID: UUID
    $start: DateTime
    $end: DateTime
    $minRegularFee: Float
    $minHolidayFee: Float
    $states: [String!]
    $types: [UserServiceType!]
  ) {
    listServices(
      serviceID: $serviceID
      start: $start
      end: $end
      minRegularFee: $minRegularFee
      minHolidayFee: $minHolidayFee
      states: $states
      types: $types
    ) {
      ... on mobile_service {
        serviceArea {
          name
          shorthandName
          comment
          confidentialComment
        }
        details {
          ...assignedServiceFields
        }
      }
      ... on office_service {
        serviceArea {
          name
          shorthandName
          comment
          confidentialComment
          address {
            city
            street
            country
            houseNr
            zipCode
            state
          }
        }
        details {
          ...assignedServiceFields
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_ASSIGNED_SUBSTITUTE}
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const SERVICE_WITH_SUBSTITUTE: DocumentNode = gql`
  query ListServices($serviceID: UUID) {
    listServices(serviceID: $serviceID) {
      ... on mobile_service {
        details {
          ...serviceUrlsPartner
        }
        serviceArea {
          comment
          coordinates
        }
      }
      ... on office_service {
        details {
          ...serviceUrlsPartner
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_PARTNER_URL}
`;

export const LIST_SERVICES_WITH_SUBSTITUTE: DocumentNode = gql`
  query ListServices(
    $serviceID: UUID
    $start: DateTime
    $end: DateTime
    $minRegularFee: Float
    $minHolidayFee: Float
    $published: Boolean
    $states: [String!]
    $types: [UserServiceType!]
    $take: Int
    $cursor: UUID
  ) {
    listServices(
      serviceID: $serviceID
      start: $start
      end: $end
      minRegularFee: $minRegularFee
      minHolidayFee: $minHolidayFee
      published: $published
      states: $states
      types: $types
      take: $take
      cursor: $cursor
    ) {
      ... on mobile_service {
        details {
          ...serviceFieldsPartner
        }
        serviceArea {
          comment
          coordinates
        }
      }
      ... on office_service {
        details {
          ...serviceFieldsPartner
        }
        serviceArea {
          comment
          address {
            ...addressFields
          }
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_PARTNER}
  ${DOCTOR_FRAGMENT}
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const SERVICE_DETAIL_PAGE: DocumentNode = gql`
  query ListServices(
    $serviceID: UUID
    $start: DateTime
    $end: DateTime
    $minRegularFee: Float
    $minHolidayFee: Float
    $published: Boolean
    $states: [String!]
    $types: [UserServiceType!]
    $take: Int
    $cursor: UUID
  ) {
    listServices(
      serviceID: $serviceID
      start: $start
      end: $end
      minRegularFee: $minRegularFee
      minHolidayFee: $minHolidayFee
      published: $published
      states: $states
      types: $types
      take: $take
      cursor: $cursor
    ) {
      ... on mobile_service {
        details {
          ...serviceFieldsServiceDetailPage
        }
        serviceArea {
          comment
          coordinates
        }
      }
      ... on office_service {
        details {
          ...serviceFieldsServiceDetailPage
        }
        serviceArea {
          comment
          address {
            ...addressFields
          }
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_SERVICE_DETAIL_PAGE}
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const FETCH_NEW_SERVICE: DocumentNode = gql`
  query ListServices($serviceID: UUID) {
    listServices(serviceID: $serviceID) {
      ... on mobile_service {
        serviceArea {
          id
          name
          shorthandName
          coordinates
          comment
        }
        details {
          ...serviceFieldsPartner
        }
      }
      ... on office_service {
        serviceArea {
          id
          name
          shorthandName
          comment
        }
        details {
          ...serviceFieldsPartner
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_PARTNER}
  ${ADDRESS_FRAGMENT}
  ${SERVICEAREA_FRAGMENT}
  ${DOCTOR_FRAGMENT}
`;

export const LIST_SERVICES_PUBLIC_MAX: DocumentNode = gql`
  query ListServices(
    $serviceID: UUID
    $start: DateTime
    $end: DateTime
    $minRegularFee: Float
    $minHolidayFee: Float
    $states: [String!]
    $types: [UserServiceType!]
    $take: Int
    $cursor: UUID
  ) {
    listServices(
      serviceID: $serviceID
      start: $start
      end: $end
      minRegularFee: $minRegularFee
      minHolidayFee: $minHolidayFee
      states: $states
      types: $types
      take: $take
      cursor: $cursor
    ) {
      ... on mobile_service {
        details {
          ...publicServiceFields
        }
        serviceArea {
          comment
          coordinates
        }
      }
      ... on office_service {
        details {
          ...publicServiceFields
        }
        serviceArea {
          comment
          address {
            ...addressFields
          }
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_PUBLIC_MAX}
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const LIST_SERVICES_PUBLIC: DocumentNode = gql`
  query ListServices(
    $serviceID: UUID
    $start: DateTime
    $end: DateTime
    $minRegularFee: Float
    $minHolidayFee: Float
    $states: [String!]
    $types: [UserServiceType!]
    $take: Int
    $cursor: UUID
  ) {
    listServices(
      serviceID: $serviceID
      start: $start
      end: $end
      minRegularFee: $minRegularFee
      minHolidayFee: $minHolidayFee
      states: $states
      types: $types
      take: $take
      cursor: $cursor
    ) {
      ... on mobile_service {
        details {
          ...publicServiceFields
        }
        serviceArea {
          comment
          coordinates
        }
      }
      ... on office_service {
        details {
          ...publicServiceFields
        }
        serviceArea {
          comment
          address {
            ...addressFields
          }
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_PUBLIC}
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const LIST_SERVICES_PUBLIC_NEW: DocumentNode = gql`
  query ListServicesNew($where: serviceWhereInput) {
    services(where: $where) {
      ... on mobile_service {
        details {
          ...publicServiceFields
        }
        serviceArea {
          comment
          coordinates
        }
      }
      ... on office_service {
        details {
          ...publicServiceFields
        }
        serviceArea {
          comment
          address {
            ...addressFields
          }
        }
      }
    }
  }
  ${SERVICE_FRAGMENT_PUBLIC}
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const SPECIALIZATIONS_QUERY: DocumentNode = gql`
  query {
    listSpecializations
  }
`;

export const INSTITUTIONS_QUERY: DocumentNode = gql`
  query {
    listInstitutionTypes
  }
`;

export const SERVICE_AREAS_QUERY: DocumentNode = gql`
  query QueryServiceAreas($state: String) {
    listServiceAreas(state: $state, showAll: false) {
      id
      name
    }
  }
`;

export const LIST_SERVICE_AREAS: DocumentNode = gql`
  query listServiceAreas($state: String, $showAll: Boolean, $cursor: UUID, $take: Int) {
    listServiceAreas(state: $state, showAll: $showAll, cursor: $cursor, take: $take) {
      ... on servicearea {
        ...serviceareaFields
        __typename
      }
      __typename
    }
  }
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const LIST_SERVICE_AREA_LOCATIONS: DocumentNode = gql`
  query listServiceAreaLocations($serviceAreaID: UUID) {
    listServiceAreaLocations(serviceAreaID: $serviceAreaID) {
      mobileServices {
        id
        name
      }
      officeServices {
        id
        name
      }
      __typename
    }
  }
`;

export const SERVICE_AREAS_DETAILS_QUERY: DocumentNode = gql`
  query {
    profile {
      institutions {
        establishments {
          id
          serviceArea {
            ...serviceAreaFields
          }
        }
      }
    }
  }
  ${SERVICEAREA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const MESSAGES_QUERY: DocumentNode = gql`
  query getMessages(
    $unread: Boolean,
    $cursor: UUID,
    $take: Int) {
      profile {
        messages (
          unread: $unread,
          cursor: $cursor,
          take: $take) {
            ...publicMessageFields
        }
      }
  }
  ${MESSAGE_FRAGMENT}
`;

export const SERVICES_WITH_EMERGENCYNOTES: DocumentNode = gql`
  query listServices(
    $serviceID: UUID
    $start: DateTime
    $end: DateTime
    $minRegularFee: Float
    $minHolidayFee: Float
    $states: [String!]
    $types: [UserServiceType!]
    $take: Int
    $emergencynotes: Boolean
    $cursor: UUID
  ) {
    listServices(
      serviceID: $serviceID
      start: $start
      end: $end
      minRegularFee: $minRegularFee
      minHolidayFee: $minHolidayFee
      states: $states
      types: $types
      take: $take
      emergencynotes: $emergencynotes
      cursor: $cursor
    ) {
      ... on mobile_service {
        details {
          ...publicServiceFields
          __typename
        }
        __typename
      }
      ... on office_service {
        details {
          ...publicServiceFields
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${ADDRESS_FRAGMENT}
  ${SERVICEAREA_FRAGMENT}
  ${SERVICE_FRAGMENT_PUBLIC}
`;
export const QUERY_INSTITUTIONS = gql `query{
  profile{
    institutions {
      id
      bsnr
      name
      type
      verified
      kvSeats
      address {
        ...addressFields
      }
      debtor {
        ...debtorFields
      }
      establishments {
        id
        bsnr
        name
        doctors {
          ...doctorFields
        }
        billingAddress {
          ...addressFields
        }
        serviceArea {
          ...serviceareaFields
        }
      }
    }
  }
}
${ADDRESS_FRAGMENT}
${DEBTOR_FRAGMENT}
${BANKACCOUNT_FRAGMENT}
${DOCTOR_FRAGMENT}
${SERVICEAREA_FRAGMENT}
`;
export const QUERY_INSTITUTIONS_WITH_SUBMISSIONS = gql `query{
  profile{
    institutions {
      id
      bsnr
      name
      type
      verified
      kvSeats
      address {
        ...addressFields
      }
      debtor {
        ...debtorFields
      }
      establishments {
        id
        bsnr
        name
        doctors {
          ...doctorFields
        }
        billingAddress {
          ...addressFields
        }
        serviceArea {
          ...serviceareaFields
        }
        submissions {
          ...submissionFields
        }
      }
    }
  }
}
${ADDRESS_FRAGMENT}
${DEBTOR_FRAGMENT}
${BANKACCOUNT_FRAGMENT}
${DOCTOR_FRAGMENT}
${SERVICEAREA_FRAGMENT}
${SUBMISSIONS_FRAGMENT}
`;

export const SERVICE_MESSAGES: DocumentNode = gql`
  query listMessages($userID: UUID!, $service: UUID!) {
    listMessages(userID: $userID, service: $service) {
      id
      createdOn
      confirmedOn
      service_id
      subject
      content
      metadata
      needs_confirmation
      __typename
    }
  }
`;

export const SERVICE_MESSAGES_SENT: DocumentNode = gql`
  query sentMessagesList($service: UUID!) {
    sentMessagesList(service: $service) {
      id
      createdOn
      service_id
      subject
      content
      recipient_id
      service {
        establishment {
          name
        }
        establishmentDoctor {
          name
        }
        substitute {
          details {
            user {
              title
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const SERVICE_INFORMATION: DocumentNode = gql`
  query getServiceInformation($serviceID: UUID!) {
    getServiceInformation(serviceID: $serviceID) {
      default_contract
      contract_info
      bag_info
      cardreader_info
      medical_documents_info
      misc_info
    }
  }
`;
