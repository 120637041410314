import Quote from '@/definitions/interfaces/locales/Quote.i';
import Question from '@/definitions/interfaces/locales/Question.i';
import AdvantageColumn from '@/definitions/interfaces/locales/AdvantageColumn.i';

export default {
  META: {
    TITLE: 'Dienstüberlassung für MVZ',
    DESCRIPTION: 'Gerade als MVZ mit oftmals mehreren Betriebsstätten ist eine zentrale Organisation der Vertretung der Bereitschaftsdienste enorm wichtig.',
  },
  HEADING: 'Dienstüberlassung für MVZ',
  HEADING_SUB: 'So funktioniert es',
  HEADING_BANNER: 'Dienstüberlassung für MVZ',
  DESCRIPTION: [
    'Insbesondere für MVZ mit mehreren Standorten ist eine effiziente Organisation der Bereitschaftsdienstvertretung von großer Bedeutung. Unsere eigens entwickelte Software ermöglicht es Ihnen, alle Standorte mit den zugeordneten Ärzten individuell anzulegen und zu verwalten, sodass Sie stets den Überblick behalten.',
    'Als MVZ profitieren Sie zudem von Mengenrabatten bei der Überlassung von KV-Diensten. Je mehr Dienste Sie auf arztpool24.de anbieten, desto mehr können Sie sparen.',
  ],
  BUTTON_TEST_IT: 'Jetzt unverbindlich testen',
  SEPARATOR: 'KV-Dienste eintragen und sicher vertreten lassen',
  QUOTE: {
    NORMAL: '»Wir nutzen Apps zur Navigation, erledigen unsere Einkäufe problemlos online und kommunizieren über das Handy.',
    HIGHLIGHT: 'Warum also nicht auch unsere KV-Dienste digital organisieren?«',
    NAME: 'Christian Kleiner',
    POSITION: 'Leiter Dienstvergabe arztpool24.de',
  } as Quote,
  QUESTIONS: [
    {
      QUESTION: 'Welche Unterlagen/ Angaben brauche ich für eine erfolgreiche Registrierung?',
      ANSWER: 'Für eine erfolgreiche Registrierung als Honorararzt bei arztpool24.de be­nö­tigen Sie Ihre Approbationsurkunde, eine aktuelle Bestätigung Ihrer Be­rufs­haft­pflicht­ver­sicherung sowie falls vorhanden eine Fach­arzt­ur­kunde. Alles im Format PDF, nicht größer als 10 MB. Ebenfalls brauchen Sie Ihre Steuernummer und Ihre Bankverbindung für die Anmeldung. Ohne diese Angaben und Dokumente können wir Ihre Registrierung nicht verifizieren.',
    },
    {
      QUESTION: 'Was passiert nach meiner Dienstbewerbung?',
      ANSWER: 'Nach der Dienstbewerbung bekommen wir angezeigt, dass Sie sich auf einen oder mehrere Dienste beworben haben. Wenn Sie dann für einen oder mehrere Dienste als Vertreter ausgewählt werden, werden Sie per Mail über die Dienstübernahme benachrichtigt. Im Portal muss zuerst der Kunden und dann Sie dem Dienstvertretungsvertrag zustimmen. Erst dann werden Ihre Daten an den Kunden übermittelt, sodass er die KV über seine Dienstvertretung informieren kann.',
    },
    {
      QUESTION: 'Wie bekomme ich meine Unterlagen für den Dienst?',
      ANSWER: 'Sobald wir den Kunden darüber informieren, dass Sie seinen Dienst übernehmen, wird er mit Ihnen Kontakt aufnehmen und die Übergabe und Rückgabe der Unterlagen wie Kartenlesegerät, Notfallscheine, Kassenrezepte oder Krankenhauseinweisungen absprechen.',
    },
    {
      QUESTION: 'Muss ich meine Berufshaftpflichtversicherung jedes Jahr nachweisen?',
      ANSWER: 'Meistens verlängern sich die Berufshaftpflichtversicherungen nur um ein Kalenderjahr (1. Januar bis 31.12.). Da wir nicht wissen können, ob Sie den Vertrag gekündigt oder die Prä­mie bezahlt haben, müssen Sie uns einmal im Jahr das Wei­ter­be­stehen schriftlich bestätigen. Eine gültige Bestätigung der Versicherung gibt es erst NACH der Ver­län­ge­rung, in den meisten Fällen also frühestens Mitte Januar.',
    },
    {
      QUESTION: 'Wo finde ich meine Steuernummer?',
      ANSWER: 'Sie finden Ihre Steuernummer auf jedem Schreiben Ihres Finanzamtes oben links. Bitte nicht mit der Steueridentifikationsnummer auf der Gehaltsabrechnung verwechseln.',
    },
  ] as Question[],
  ADVANTAGES: {
    HEADING: 'Ihre Vorteile bei KV-Dienstvertretungen',
    OFFER: 'Bereits ab 99€ KV-Dienste vertreten lassen',
    COLUMNS: [
      {
        HEADING: 'Unkomplizierte Abwicklung',
        ENTRIES: [
          'Zuverlässige Vertretung bei terminlichen Überschneidungen',
          'Unterstützende Dienstkoordination durch arztpool24.de',
          'Einfache Korrespondenzabwicklung mit Vertretern über arztpool24.de',
          'Bequeme Verwaltung von Abrechnungen und Verträgen über arztpool24.de',
        ],
      },
      {
        HEADING: 'Sicherheit',
        ENTRIES: [
          'Approbierte KV-Dienst-Vertreter mit deutscher Approbation',
          'Hochqualifizierte Dienstvertretungsärzte in ganz Deutschland',
          'Ausschließlich haftpflichtversicherte Vertreter',
          'Transparente und faire Kosten, nur bei erfolgreicher Vermittlung',
        ],
      },
      {
        HEADING: 'Ausstattung der Vertreter',
        ENTRIES: [
          'Vertreter erhalten alle benötigten Unterlagen und Kartenlesegerät direkt vom Kunden oder arztpool24.de',
          'Eigenverantwortliche Unterkunftsbuchung durch Vertreter',
        ],
      },
      {
        HEADING: 'Verlässliche Vertretung',
        ENTRIES: [
          'Bequeme Dienstkoordination',
          'Zugesicherte Vertretung der Dienste',
          'Zufriedenheitsgarantie',
        ],
      },
    ] as AdvantageColumn[],
  },
};
