import Quote from '@/definitions/interfaces/locales/Quote.i';
import Question from '@/definitions/interfaces/locales/Question.i';
import AdvantageColumn from '@/definitions/interfaces/locales/AdvantageColumn.i';

export default {
  META: {
    TITLE: 'KV-Dienste vertreten lassen',
    DESCRIPTION: '👉🏻 Lassen Sie Ihre KV-Dienste sicher über arztpool24 vertreten. Wir koordinieren zuverlässig Ihre Dienste und statten auf Wunsch Ihre Vertretungsärzte aus.',
  },
  HEADING: 'Dienstüberlassung für Praxen mit einem oder mehreren Ärzten',
  HEADING_SUB: 'So funktioniert es',
  HEADING_BANNER: 'KV-Dienste sicher vertreten lassen',
  DESCRIPTION: [
    'Niedergelassene Ärzte übernehmen oft KV-Dienste, die jedoch oft mit ihrem Alltag kollidieren. Moderne Ärzte setzen daher auf die Dienstüberlassung an Vertretungsärzte über arztpool24.de.',
    'Unabhängig von Ihrer Praxisart - sei es eine Einzelpraxis, Gemeinschaftspraxis, Berufsausübungsgemeinschaft oder Praxisgemeinschaft - arztpool24.de kümmert sich um Ihre Bereitschaftsdienste. Über Ihren Account verwalten und organisieren Sie bequem alle Ärzte und KV-Dienste zentral.',
    'Profitieren Sie von zahlreichen Vorteilen und überlassen Sie Ihre KV-Dienste zuverlässig arztpool24.de.',
  ],
  BUTTON_TEST_IT: 'Jetzt unverbindlich testen',
  SEPARATOR: 'KV-Dienste eintragen und sicher vertreten lassen',
  QUOTE: {
    NORMAL: 'arztpool24.de definiert neue Maßstäbe für die Organisation von kassenärztlichen Bereitschaftsdiensten.',
    HIGHLIGHT: '»Für uns steht bei der Vermittlung von KV-Diensten der Mehrwert für alle Beteiligten im Vordergrund.«',
    NAME: 'Yven von Rhedey',
    POSITION: 'Geschäftsführer arztpool24 GmbH',
  } as Quote,
  QUESTIONS: [
    {
      QUESTION: 'Wie werde ich Kunde bei arztpool24, um für meine KV-Dienste einen Vertreter zu finden?',
      ANSWER: 'Zuerst müssen Sie sich registrieren und ein Kundenkonto anlegen und dort die Angaben zu Ihrer Arztpraxis und sich selbst ergänzen (Dienstgebiet, LANR usw.).',
    },
    {
      QUESTION: 'Wie übermittle ich meine Dienste an arztpool24?',
      ANSWER: 'Sie haben in Ihrem Account die Eingabemaske „Dienst eintragen“, dort werden alle Informationen abgefragt. Datum, Uhrzeit, Fahrdienst oder Sitzdienst, Dienstgebiet, Vergütungsspanne, Scheinbeteiligung. Um die Dienstvertretung möglichst attraktiv zu gestalten können Sie eine Preisspanne bei der Vergütung angeben, die Sie bereit sind zu bezahlen. Innerhalb dieser Preisspanne geben die Vertreter dann ein Gebot ab.',
    },
    {
      QUESTION: 'Was ist die Option Sofortzusage?',
      ANSWER: 'Um die Vertretersuche zu beschleunigen können Sie auch die Option Sofortzusage wählen. Dann können Sie den Vertreter nicht selbst aussuchen, denn der erste Vertreter, der die Option wählt vertritt Ihren Dienst, zum niedrigsten angebotenen Honorar.',
    },
    {
      QUESTION: 'Was ist die Option Rundum-Sorglos?',
      ANSWER: 'In der Option Rundum-Sorglos kümmert sich arztpool24 um alles. Wir koordinieren den rechtzeitigen Versand der Unterlagen/des Kartenlesegerätes zwischen Ihnen und Ihrem Vertreter und stellen dem Vertreter bei Bedarf auch eine Arzttasche zur Verfügung. Wir kümmern uns außerdem um die Honorarverhandlung und um die Absprachen zwischen Ihnen und Ihrem Vertreter.',
    },
    {
      QUESTION: 'Was ist die Scheinbeteiligung?',
      ANSWER: 'Um dem Vertreter einen weiteren finanziellen Anreiz zu setzen, können neben der fixen Vergütung pro Stunde einen variablen Bestandteil anbieten. Pro behandelten Patienten/Notfallschein können Sie Ihrer Vertretung eine bestimmte Summe (siehe Preisliste) vergüten. Da Sie die Notfallscheine in Ihrer Praxis abrechnen „teilen“ Sie sich die EBM-Vergütung. Im Durchschnitt wird ein Hausbesuch mit ca. 100€ vergütet (EBM 01418 und 01212), eine Behandlung in der Bereitschaftspraxis (EBM 01212) mit ca. 15€.',
    },
    {
      QUESTION: 'Kann ich sehen, ob mein Vertreter überhaupt geeignet ist?',
      ANSWER: 'Grundsätzlich prüfen wir von arztpool24 bei jedem einzelnen Vertreter Approbation, evtl. Facharzturkunde sowie Nachweis einer Berufshaftpflichtversicherung. Besonderes Augenmerk legen wir dabei auf die Berufshaftpflichtversicherung, die wir uns nach jeder Verlängerung schriftlich bestätigen lassen. Als Entscheidungshilfe und um sich persönlich von der Eignung Ihrer Vertreter zu überzeugen, können Sie sich von jedem Bewerber auf Ihre Dienste die Approbationsurkunde, ggf. die Facharzturkunde und die letzte Bestätigung der Berufshaftpflichtversicherung ansehen.',
    },
    {
      QUESTION: 'Wie geht es nach der Übermittlung meiner Dienste weiter?',
      ANSWER: 'Die Vertreter finden Ihren Dienst auf unserem Marktplatz und können sich durch die Abgabe eines verbindlichen Angebotes auf Ihren Dienst bewerben. Sie bekommen dann sämtliche geeigneten Vertreter angezeigt und wählen einen davon als Ihre Vertretung aus. Nachdem Sie beide dem Vertretungsvertrag zugestimmt haben gilt er als verbindlich geschlossen. Außerdem können Sie Ihren Dienst mir der Sofortzusage-Option ausschreiben.',
    },
    {
      QUESTION: 'Wer rechnet Privatpatienten oder die Ausstellung von Todesbescheinigungen ab?',
      ANSWER: 'Beides wird immer vom Vertreter abgerechnet, siehe auch in den AGB. Es ist ein weiterer finanzieller Baustein die Dienstvertretung noch attraktiver zu machen.',
    },
    {
      QUESTION: 'Benötigt mein Vertreter Unterlagen von mir und wenn ja, wie bekommt er sie?',
      ANSWER: 'Ihr Vertreter benötigt bei Hausbesuchsdiensten ein mobiles Kartenlesegerät, das Ihnen wiederum die Abrechnung des EBM-Honorars erleichtert, ausreichend rosa Kassenrezepte und Notfallscheine sowie Krankenhauseinweisungs- und Krankentransportscheine. Im Sitzdienst benötigt der Vertretungsarzt zumindest rosa Kassenrezepte und Ihre Zugangsdaten für den Rechner in der Bereitschaftspraxis. Sollte er die Zugangsdaten nicht bekommen dürfen, wie aktuell in Bayern, dann auch zusätzlich ein mobiles Kartenlesegerät für die erleichterte Abrechnung des EBM-Honorars. Die Unterlagen können Sie ihm mit der Post zuschicken, an einem abgesprochenen Ort hinterlegen oder auch persönlich übergeben.',
    },
    {
      QUESTION: 'Muss ich die KV über meinen Vertreter informieren?',
      ANSWER: 'Ja unbedingt. Sobald Sie und der Vertreter dem Vertretungsvertrag per Mausklick zugestimmt haben, erhalten Sie eine formlose Tauschmeldung mit den Kontaktdaten des Vertreters zum Download. Dann können Sie Ihrer KV mitteilen, wer Ihren Dienst vertreten wird.',
    },
  ] as Question[],
  ADVANTAGES: {
    HEADING: 'Ihre Vorteile bei KV-Dienst-Vertretungen',
    OFFER: 'Bereits ab 99€ \n KV-Dienstvertretung',
    COLUMNS: [
      {
        HEADING: 'So funktioniert es',
        ENTRIES: [
          'Zuverlässige Abwicklung',
          'Professionelle Dienstkoordination durch arztpool24.de',
          'Einfache Korrespondenzverwaltung',
          'Transparente Abrechnung und Vertragsverwaltung',
        ],
      },
      {
        HEADING: 'Sicherheit',
        ENTRIES: [
          'In Deutschland approbierte KV-Dienst-Vertreter',
          'Hochqualifizierte Dienstvertretungsärzte deutschlandweit',
          'Vollständige Haftpflichtversicherung',
          'Kosten nur bei erfolgreicher Vermittlung',
          'Sie entscheiden, wer Ihren Dienst vertritt',
        ],
      },
      {
        HEADING: 'Ausstattung der Vertreter',
        ENTRIES: [
          'Vertreter erhalten benötigte Unterlagen und Kartenlesegerät',
          'Eigenverantwortliche Unterkunftsorganisation',
        ],
      },
      {
        HEADING: 'Zuverlässigkeit',
        ENTRIES: [
          'Zuverlässige Vertreter',
          'Bequeme Dienstkoordination',
          'Zugesicherte Dienstvertretung',
          'Zufriedenheitsgarantie',
        ],
      },
    ] as AdvantageColumn[],
  },
};
