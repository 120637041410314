// app imports
import { createApp } from 'vue';
import App from '@/App.vue';

// plugins
import router from '@/plugins/router';
import gtm from '@/plugins/gtm';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import amplify from '@/plugins/amplify';
import pinia from '@/plugins/pinia';
import apollo from '@/plugins/apollo';
import Vue3EasyDataTable from '@/plugins/easydatatables';
import Vue3ConfirmDialog from '@/plugins/confirmdialog';
// import '@aws-amplify/ui-vue/styles.css';

// create app instance
const app = createApp(App, {
  render: (h: any) => h(App),
});

// register plugins
app.use(router);
app.use(gtm);
app.use(apollo);
app.use(amplify);
app.use(vuetify);
app.use(i18n);
app.use(pinia);
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(Vue3ConfirmDialog);
app.component('vue3-confirm-dialog', Vue3ConfirmDialog.default);

// mount app in ./index.html
app.mount('#app');
