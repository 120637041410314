export default {
  LINKS: {
    PROVIDE_SERVICE: '@:NAVIGATION.MY_DOCTOR_POOL.LINKS.PROVIDE_SERVICE',
    REPRESENT_SERVICE: '@:NAVIGATION.MY_DOCTOR_POOL.LINKS.REPRESENT_SERVICE',
    MVZ: '@:NAVIGATION.MY_DOCTOR_POOL.LINKS.MVZ',
    ABOUT: 'About arztpool',
  },
  COPYRIGHT: 'arztpool24 GmbH - All rights reserved.',
  LEGAL: {
    IMPRINT: 'Imprint',
    PRIVACY: 'Privacy',
    CONDITIONS: 'Terms',
    COOKIES: 'Cookie-Settings',
  },
};
