<template>
  <v-app id="app-container">
    <vue3-confirm-dialog />
    <Navigation />
    <NewsTicker :items="newsItems" v-if="!signedIn"/>
    <div id="page">
      <router-view :key="$route.fullPath" />
    </div>
    <Contact v-if="!isOperator"/>
    <PageFooter />
  </v-app>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { RouterView } from 'vue-router';
  import { $tm } from '@/plugins/i18n';

  import Stores from '@/stores';

  import Navigation from '@/components/page/Navigation.vue';
  import Contact from '@/components/modals/Contact.vue';
  import PageFooter from '@/components/page/Footer.vue';
  import Login from '@/services/Login';
  import Messages from './services/Messages';

  // global styles an ui options
  import '@fortawesome/fontawesome-pro/css/all.css';
  import '@fortawesome/fontawesome-pro/js/all.js';
  import '@vuepic/vue-datepicker/dist/main.css';
  import '@/scss/app.scss';
  import Profile from './services/Profile';
  import Partner from '@/services/Partner';
  import Substitute from '@/services/Substitute';

  import NewsItem from '@/definitions/interfaces/locales/NewsItem.i';
  import NewsTicker from '@/components/content/NewsTicker.vue';

  export default defineComponent({
    name: 'App',
    components: {
      RouterView,
      Navigation,
      Contact,
      PageFooter,
      NewsTicker
    },
    setup() {
      // initialize stores on app start
      // this needs to be done in a setup-function, otherwise pinia doesn't work
      Stores.init();

      const { signedIn, userType } = Stores.userDataStoreToRefs();
      const { isOperator } = Stores.profileDataStoreToRefs();

      return {
        signedIn,
        userType,
        isOperator,

        newsItems: $tm('NEWS_TICKER.ITEMS') as NewsItem[],
      };
    },
    async created() {
      await Login.fetchUser();

      if (this.signedIn && !this.isOperator) {
        await Profile.fetchProfile();
        if (Stores.profileData.isPartner) {
          if (Stores.partnerData.services.length === 0) {
            Partner.fetchPartner();
          }
        }
        else if (Stores.profileData.isSubstitute) {
          if (Stores.substituteData.assignments.length === 0) {
            Substitute.fetchSubstitute(true);
          }
        }
        Messages.fetchMessages();
      }
    },
  });
</script>

<style lang="scss" scoped>
  #app-container {
    min-width: 400px;
  }
</style>
