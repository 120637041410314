import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  // public routes
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: 'HOME.META.TITLE',
      ogTitle: 'HOME.META.TITLE',
    },
  },
  {
    path: '/kv-dienst-vertreten-lassen',
    name: 'ProvideService',
    component: () => import('@/views/ProvideService.vue'),
    meta: {
      title: 'PROVIDE_SERVICE.META.TITLE',
      description: 'PROVIDE_SERVICE.META.DESCRIPTION',
      ogTitle: 'PROVIDE_SERVICE.META.TITLE',
      ogDescription: 'PROVIDE_SERVICE.META.DESCRIPTION',
    },
  },
  {
    path: '/kv-dienst-uebernehmen',
    name: 'RepresentService',
    component: () => import('@/views/RepresentService.vue'),
    meta: {
      title: 'REPRESENT_SERVICE.META.TITLE',
      description: 'REPRESENT_SERVICE.META.DESCRIPTION',
      ogTitle: 'PROVIDE_SERVICE.META.TITLE',
      ogDescription: 'REPRESENT_SERVICE.META.DESCRIPTION',
    },
  },
  {
    path: '/mvz',
    name: 'MVZ',
    component: () => import('@/views/MVZ.vue'),
    meta: {
      title: 'MVZ.META.TITLE',
      description: 'MVZ.META.DESCRIPTION',
      ogTitle: 'MVZ.META.TITLE',
      ogDescription: 'MVZ.META.DESCRIPTION',
    },
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('@/views/AboutUs.vue'),
    meta: {
      title: 'ABOUT_US.META.TITLE',
      description: 'ABOUT_US.META.DESCRIPTION',
      ogTitle: 'ABOUT_US.META.TITLE',
      ogDescription: 'ABOUT_US.META.DESCRIPTION',
    },
  },
  {
    path: '/wizard',
    name: 'Wizard',
    component: () => import('@/views/Wizard.vue'),
    meta: {
      title: 'WIZARD.META.TITLE',
      ogTitle: 'WIZARD.META.TITLE',
    },
  },
  {
    path: '/services/public',
    name: 'ServicesPublic',
    component: () => import('@/views/ServiceListing.vue'),
    meta: {
      title: 'SERVICE_LISTING.META.TITLE',
      ogTitle: 'SERVICE_LISTING.META.TITLE',
    },
  },
  {
    path: '/service',
    name: 'ServiceDetail',
    component: () => import('@/views/ServiceDetail.vue'),
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import('@/views/Imprint.vue'),
    meta: {
      title: 'IMPRINT.META.TITLE',
      ogTitle: 'IMPRINT.META.TITLE',
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue'),
    meta: {
      title: 'PRIVACY.META.TITLE',
      ogTitle: 'PRIVACY.META.TITLE',
    },
  },
  {
    path: '/conditions',
    name: 'Conditions',
    component: () => import('@/views/Conditions.vue'),
    meta: {
      title: 'CONDITIONS.META.TITLE',
      ogTitle: 'CONDITIONS.META.TITLE',
    },
  },
  {
    path: '/confirmSignup',
    name: 'Confirm',
    component: () => import('@/views/Confirm.vue'),
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    redirect: to => {
      return { path: '/', query: { login: 'true' } };
    },
  },

  // authentication required routes
  {
    path: '/partners/add-service/single-entry',
    name: 'SingleEntry',
    component: () => import('@/views/partners/services/SingleEntry.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/partners/add-service/bulk-entry',
    name: 'BulkEntry',
    component: () => import('@/views/partners/services/BulkEntry.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/Services.vue'),
    redirect: '/services/overview',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'overview',
        name: 'ServicesOverview',
        component: () => import('@/views/services/Overview.vue'),
      },
      {
        path: 'register',
        name: 'ServicesRegister',
        component: () => import('@/views/services/Register.vue'),
        meta: {
          requiredUserType: 'partner',
        },
      },
      {
        path: 'published',
        name: 'ServicesPublished',
        component: () => import('@/views/services/Listing.vue'),
        meta: {
          requiredUserType: 'partner',
        },
      },
      {
        path: 'marketplace',
        name: 'ServicesMarketplace',
        component: () => import('@/views/services/Marketplace.vue'),
        meta: {
          requiredUserType: 'substitute',
          title: 'SERVICE_MARKETPLACE.META.TITLE',
          ogTitle: 'SERVICE_MARKETPLACE.META.TITLE',
        },
      },
      {
        path: 'watchlist',
        name: 'ServicesWatchlist',
        component: () => import('@/views/services/Listing.vue'),
        meta: {
          requiredUserType: 'substitute',
        },
      },
      {
        path: 'applications',
        name: 'ServicesApplications',
        component: () => import('@/views/services/Listing.vue'),
        meta: {
          requiredUserType: 'substitute',
        },
      },
      {
        path: 'assigned',
        name: 'ServicesAssigned',
        component: () => import('@/views/services/Listing.vue'),
        meta: {
          requiredUserType: 'substitute',
        },
      },
      {
        path: 'occupied',
        name: 'ServicesOccupied',
        component: () => import('@/views/services/Listing.vue'),
        meta: {
          requiredUserType: 'partner',
        },
      },
      {
        path: 'concluded',
        name: 'ServicesConcluded',
        component: () => import('@/views/services/Listing.vue'),
      },
      {
        path: 'drafts',
        name: 'ServicesDrafts',
        component: () => import('@/views/services/Listing.vue'),
        meta: {
          requiredUserType: 'partner',
        },
      },
    ],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    redirect: '/profile/account',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'account',
        name: 'Account',
        component: () => import('@/views/profile/Account.vue'),
      },
      {
        path: 'basedata',
        name: 'BaseData',
        component: () => import('@/views/profile/BaseData.vue'),
      },
      {
        path: 'institutions',
        name: 'PartnerInstitutions',
        component: () => import('@/views/partners/profile/Institutions.vue'),
        meta: {
          requiredUserType: 'partner',
        },
      },
      {
        path: 'establishments',
        name: 'PartnerEstablishments',
        component: () => import('@/views/partners/profile/Establishments.vue'),
        meta: {
          requiredUserType: 'partner',
        },
      },
      {
        path: 'billing',
        name: 'SubstituteBilling',
        component: () => import('@/views/substitutes/profile/Billing.vue'),
        meta: {
          requiredUserType: 'substitute',
        },
      },
      {
        path: 'insurances',
        name: 'SubstituteInsurances',
        component: () => import('@/views/substitutes/profile/Insurance.vue'),
        meta: {
          requiredUserType: 'substitute',
        },
      },
      {
        path: 'specialization',
        name: 'SubstituteSpecialization',
        component: () => import('@/views/substitutes/profile/Specialization.vue'),
        meta: {
          requiredUserType: 'substitute',
        },
      },
    ],
  },
  {
    path: '/partners/service-management',
    name: 'PartnerServiceManagement',
    component: () => import('@/views/partners/ServiceManagement.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/substitutes/service-management',
    name: 'SubstituteServiceManagement',
    component: () => import('@/views/substitutes/ServiceManagement.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/views/Documents.vue'),
    redirect: '/documents/contracts',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'contracts',
        name: 'Contracts',
        component: () => import('@/views/documents/Contracts.vue'),
      },
      {
        path: 'invoice-templates',
        name: 'SubstituteInvoiceTemplates',
        component: () => import('@/views/substitutes/documents/InvoiceTemplates.vue'),
        meta: {
          requiredUserType: 'substitute',
        },
      }
    ],
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('@/views/Messages.vue'),
    meta: {
      requiresAuth: true,
      title: 'MESSAGES.META.TITLE',
      ogTitle: 'MESSAGES.META.TITLE',
    },
  },
  {
    path: '/operators',
    name: 'Operators',
    component: () => import('@/views/Operators.vue'),
    meta: {
      requiresAuth: true,
      requiredUserType: 'operator',
    },
    children: [
      {
        path: 'operator_services',
        name: 'OperatorServices',
        component: () => import('@/views/operators/OperatorServices.vue'),
      },
      {
        path: 'operator_servicesubmissions',
        name: 'OperatorServiceSubmissions',
        component: () => import('@/views/operators/OperatorServiceSubmissions.vue'),
      },
      {
        path: 'operator_allinclusive_services',
        name: 'OperatorAllInclusiveServices',
        component: () => import('@/views/operators/OperatorAllInclusiveServices.vue'),
      },
      {
        path: 'operator_tier_services',
        name: 'OperatorTierServices',
        component: () => import('@/views/operators/OperatorTierServices.vue'),
      },
      {
        path: 'operator_single_service',
        name: 'OperatorSingleService',
        component: () => import('@/views/operators/OperatorSingleService.vue'),
      },
      {
        path: 'operator_users',
        name: 'OperatorUsers',
        component: () => import('@/views/operators/OperatorUsers.vue'),
      },
      {
        path: 'operator_single_user',
        name: 'OperatorSingleUser',
        component: () => import('@/views/operators/OperatorSingleUser.vue'),
      },
      {
        path: 'operator_serviceareas',
        name: 'OperatorServiceAreas',
        component: () => import('@/views/operators/OperatorServiceAreas.vue'),
      },
      {
        path: 'operator_single_servicearea',
        name: 'OperatorSingleServiceArea',
        component: () => import('@/views/operators/OperatorSingleServiceArea.vue'),
      },
      {
        path: 'operator_accounting',
        name: 'OperatorAccounting',
        component: () => import('@/views/operators/OperatorAccounting.vue'),
      },
      {
        path: 'operator_reporting',
        name: 'OperatorReporting',
        component: () => import('@/views/operators/OperatorReporting.vue'),
      },
    ],
  },

  // fallback route for 404 error
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/PageNotFound.vue'),
    meta: {
      title: 'PAGE_NOT_FOUND.META.TITLE',
      ogTitle: 'PAGE_NOT_FOUND.META.TITLE',
    },
  }
];

export default routes;
