export default {
  HEADING: 'Nutzerverwaltung',
  TABLE_HEADERS: {
    ROLES: 'Rollen',
    FIRSTNAME: 'Vorname',
    LASTNAME: 'Nachname',
    DEBTOR_NUMBER: 'Kundennummer',
    EMAIL: 'E-Mail',
    VERIFIED: 'Verifiziert',
    ACTIONS: 'Aktionen',
  },
  FORM_LABELS: {
    SEARCH_TERM: 'Suche in Vorname, Nachname, E-Mail und Kundennummer...',
    ROLE: 'Rolle',
    VERIFIED: 'Verifiziert',
    SELECT_VALUES: {
      ROLES_LIST: ['Alle', 'Kunden', 'Vertreter'],
      VERIFIED_LIST: ['Alle', 'Verifiziert', 'Nicht Verifiziert'],
    },
    SUBMIT: 'Suchen',
  },
  SINGLE_USER: {
    HEADING: 'Nutzer',
    MOBILE_SERVICE: 'Fahrdienst',
    OFFICE_SERVICE: 'Sitzdienst',
    SERVICE_TABLE_HEADERS_PARTNER: {
      TYPE: 'Dienstart',
      ESTABLISHMENT: 'Betriebsstätte',
      START: 'Dienststart',
      END: 'Dienstende',
      SERVICE_AREA: 'Dienstgebiet',
      STATE: 'Bundesland',
      SUBSTITUTE: 'Vertreter',
      ACTIONS: 'Aktionen',
    },
    SERVICE_TABLE_HEADERS_SUSBTITUTE: {
      TYPE: 'Dienstart',
      START: 'Dienststart',
      END: 'Dienstende',
      SERVICE_AREA: 'Dienstgebiet',
      STATE: 'Bundesland',
      ACTIONS: 'Aktionen',
    },
    FORM_LABELS: {
      TITLE: 'Titel',
      FIRST_NAME: 'Vorname',
      LAST_NAME: 'Nachname',
      EMAIL: 'eMail',
      PHONE: 'Festnetz',
      MOBILE_1: 'Handy 1',
      MOBILE_2: 'Handy 2',
      FAX: 'Faxnummer',
      BIRTHDATE: 'Geburtsdatum',
    },
    PARTNER: {
      INSTITUTION: 'Einrichtung',
      ESTABLISHMENTS: 'Betriebsstätten',
      ADDRESS: 'Adresse',
      BILLING_ADDRESS: 'Rechnungsadresse',
      INSTITUTION_MVZ: 'MVZ',
      INSTITUTION_SINGLE: 'Einzelpraxis',
      KV_SEATS: 'KV Sitze insgesamt',
      IS_VERIFIED: 'Einrichtung bereits verifiziert.',
      BANK: 'Bankverbindung',
      SERVICE_AREA: 'Dienstgebiet',
      SERVICES: 'Dienste',
      NO_SERVICE_AREA: 'Noch kein Dienstgebiet ausgewählt',
      ESTABLISHMENT_DOCTORS: 'Betriebsstättenärzte',
      NEW_ESTABLISHMENT_DOCTOR: 'Betriebsstättenarzt hinzufügen',
      NEW_ESTABLISHMENT: 'Betriebsstätte hinzufügen',
      DELETE_DOCTOR_WARNING_TITLE: 'Löschen?',
      DELETE_DOCTOR_WARNING_MESSAGE: 'Diesen Betriebsstättenarzt löschen?',
      FORM_LABELS: {
        NAME: 'Name',
        ESTABLISHMENT_NAME: 'Betriebsstättenname',
        BSNR: 'Betriebsstättennummer',
        STREET: 'Straße',
        HOUSENUMBER: 'Hausnummer',
        ZIP: 'Postleitzahl',
        CITY: 'Ort',
        STATE: 'Bundesland',
        ACCOUNT_HOLDER: 'Kontoinhaber',
        IBAN: 'IBAN',
        BIC: 'BIC',
        BANK: 'Bank',
        LANR: 'LANR',
        KV_SEATS: 'KV Sitze',
        SUBMIT: 'Speichern',
        SEARCH: 'Suchen',
        EDIT: 'Editieren',
        FROM: 'Von',
        TO: 'Bis',
        SERVICE_AREA: 'Dienstgebiet',
        DOWNLOAD: 'Download',
        DELETE: 'Löschen',
        VERIFY: 'Einrichtung verifizieren',
      },
      SERVICE_AREA_MODAL: {
        HEADING: 'Dienstgebiet bearbeiten',
        DESCRIPTION: 'Wählen Sie das Bundesland, in dem diese Betriebsstätte liegt und anschließend das passende Dienstgebiet aus.',
        CLOSE: 'Schließen',
      },
    },
    SUBSTITUTE: {
      MEMO: 'Memo (intern)',
      ADDRESS: 'Adresse',
      BILLING_ADDRESS: 'Rechnungsadresse',
      BANKACCOUNT: 'Bankverbindung & Steuernummer',
      SPECIALIZATION: 'Fachrichtung',
      SPECIALIZATION_VERIFIED: 'Fachrichtung bereits verifiziert',
      VERIFY_SPECIALIZATION: 'Facharzt verifizieren',
      TRAINING_CONFIRMED: 'Der/die Vertreter*in hat uns bestätigt, dass er/sie sich mind. im 3. Jahr seiner/ihrer Weiterbildung befinden.',
      CONFIRM_TRAINING_TEXT: 'Befindet sich der/die Vertreter*in mind. im 3. Jahr seiner/ihrer Weiterbildung?',
      CONFIRM_TRAINING: 'Weiterbildung bestätigen',
      INSURANCES: 'Versicherungen',
      NEW_INSURANCE: 'Neue Versicherung eintragen',
      APPLICATIONS: 'Bewerbungen',
      ASSIGNMENTS: 'Dienste',
      MOBILE_SERVICES: 'Fahrdienste',
      OFFICE_SERVICES: 'Sitzdienste',
      LICENSE_PRESENT: 'Approbation bereits hochgeladen',
      LICENSE_NOT_PRESENT: 'Approbation noch nicht hochgeladen',
      SPECIALIZATION_CERTIFICATE_PRESENT: 'Facharzturkunde bereits hochgeladen',
      SPECIALIZATION_CERTIFICATE_NOT_PRESENT: 'Facharzturkunde noch nicht hochgeladen',
      VERIFY: 'Vertreter verifizieren',
      VERIFY_DESCRIPTION: 'Mit der Verifikation dieses Vertreters, kann dieser von Kunden aus der Bewerberliste ausgewählt werden. Bitte nur verifzieren, wenn vorher eine eingängige Prüfung der Approbation und der Facharzturkunde stattgefunden hat.',
      IS_VERIFIED: 'Vertreter wurde bereits verifiziert',
      FORM_LABELS: {
        MEMO: 'Memo - dies ist ein internes Feld und wird dem Nutzer nicht angezeigt',
        NAME: 'Name',
        STATE: 'Bundesland',
        VALID_FROM: 'Gültig von',
        VALID_TO: 'Gültig bis',
        FROM: 'Von',
        TO: 'Bis',
        SHORTHAND: 'Kürzel',
        COORDINATES: 'Koordinaten',
        COMMENT: 'Kommentar',
        LEGACY_ID: 'Legacy ID',
        CITY: 'Stadt',
        ZIP: 'PLZ',
        STREET: 'Straße',
        HOUSENUMBER: 'Hausnummer',
        ACCOUNT_HOLDER: 'Kontoinhaber',
        IBAN: 'IBAN',
        BIC: 'BIC',
        BANK: 'Bank',
        TAX_NUMBER: 'Steuernummer',
        INSURANCE_NAME: 'Name der Versicherung',
        INSURANCE_NUMBER: 'Nummer der Police',
        DOCUMENT: 'Haftpflichtversicherungsurkunde',
        SPECIALIZATION: 'Fachrichtung',
        TRAINING_CONFIRMED: 'Drittes Ausbildungsjahr abgeschlossen',
        DOCTORS_BAG: 'Arzttasche vorhanden',
        STATES: 'Bundesländer',
        DOCUMENT_LICENSE: 'Approbation',
        DOCUMENT_SPECIALIZATION: 'Facharzturkunde',
        SUBMIT: 'Speichern',
        DOWNLOAD: 'Download',
        VERIFY: 'Vertreter verifizieren',
      }
    }
  },
  USER_ROLE_LABELS: {
    SUBSTITUTE: 'Vertreter',
    PARTNER: 'Kunde',
  },
  USER_VERIFIED_LABELS: {
    VERIFIED: 'Verifiziert',
    NON_VERIFIED: 'Nicht Verifiziert',
  }
};
