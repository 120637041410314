export default {
  TITLE: {
    PREFIX: '',
    SUFFIX: ' - arztpool24',
    DEFAULT: 'Das Vermittlungsportal für Ärzte im KV-Bereitschaftsdienst',
  },
  DESCRIPTION: {
    DEFAULT: 'Lassen Sie Ihre KV-Dienste zuverlässig vertreten oder übernehmen Sie KV-Dienste. Sie konzentrieren sich auf die Arbeit. Wir kümmern uns um den Rest.',
  },
};
