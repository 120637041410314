export default {
  LINKS: {
    PROVIDE_SERVICE: '@:NAVIGATION.MY_DOCTOR_POOL.LINKS.PROVIDE_SERVICE',
    REPRESENT_SERVICE: '@:NAVIGATION.MY_DOCTOR_POOL.LINKS.REPRESENT_SERVICE',
    MVZ: '@:NAVIGATION.MY_DOCTOR_POOL.LINKS.MVZ',
    ABOUT: 'Über arztpool',
  },
  COPYRIGHT: 'arztpool24 GmbH - Alle Rechte vorbehalten.',
  LEGAL: {
    IMPRINT: 'Impressum',
    PRIVACY: 'Datenschutz',
    CONDITIONS: 'AGB',
  },
};
