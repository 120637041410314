export default {
  apollo: {
    /**
     * apollo request base uri
     * @var {string}
     */
    uri: import.meta.env.ARZTPOOL_APOLLO_URI,
  },

  amplify: {
    /**
     * amplify server region
     * @var {string}
     */
    region: import.meta.env.ARZTPOOL_AMPLIFY_REGION,

    /**
     * amplify user pool ID
     * @var {string}
     */
    userPoolId: import.meta.env.ARZTPOOL_AMPLIFY_USERPOOL_ID,

    /**
     * amplify user pool web-client ID
     * @var {string}
     */
    userPoolWebClientId: import.meta.env.ARZTPOOL_AMPLIFY_USERPOOL_WEBCLIENT_ID,
  },

  googleTagManager: {
    /**
     * google tag manager container id
     * @var {string}
     */
    id: import.meta.env.ARZTPOOL_GOOGLE_TAG_MANAGER_ID,
  },

  googleMaps: {
    /**
     * google maps api key
     * @var {string}
     */
    apiKey: import.meta.env.ARZTPOOL_GOOGLE_MAPS_API_KEY,

    /**
     * google maps initialization callback name
     * @var {string}
     */
    callbackName: 'gmapsCallback',
  },

};
