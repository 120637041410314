import NewsItem from '@/definitions/interfaces/locales/NewsItem.i';

export default {
  TITLE: 'News: ',
  ITEMS: [
    {
      TEXT: 'KV-Dienstvertretung durch arztpool24 ist nicht sozialversicherungspflichtig +++++ KV-Vertreter bei arztpool24, die rechtssichere Alternative zum Poolarzt +++++',
    }
  ] as NewsItem[],
};
