import Stores from '@/stores';
import Debug from '@/utils/Debug';

class CaptureException {

  public send(error: any) {
    const tags: {[p: string]: any[]} = {};
    tags['userId'] = Stores.userData.signedIn ? Stores.profileData.profile.id : 'guest';
    tags['userRole'] = Stores.userData.signedIn ? Stores.profileData.profile.roles[0] : 'guest';

    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    Object.entries(params).forEach(
      ([key, value]) => tags[key] = value
    );
    Debug.captureException(error, tags);
  }

}

export default new CaptureException();
