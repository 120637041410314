export default {
  HEADING: 'Dienstvertretungsvertrag',
  TEXT:[
    'Zwischen',
    '- im folgenden Auftraggeber genannt -',
    'und',
    '- im folgenden Auftragnehmer genannt -',
    'wird folgender Vertrag geschlossen',
  ],
  PREAMBLE: {
    TITLE: 'Präambel',
    TEXT: 'Der Auftraggeber ist Mitglied der Kassenärztlichen im Bundesland seiner Niederlassung und nimmt an der vertragsärztlichen Versorgung von gesetzlich krankenversicherten Patienten teil. Er ist damit verpflichtet, im Rahmen der Bereitschaftsdienstordnung seiner KV ärztliche Bereitschaftsdienste (KV-Dienste) zu übernehmen. Der Auftragnehmer ist approbierter Arzt und selbständig, freiberuflich (vertragsärztlich oder rein privatärztlich) tätig. Mit diesem Vertrag soll die einmalige Vertretung des Auftraggebers bei der Durchführung der KV-Dienste durch den Auftragnehmer geregelt werden. Eine Beschäftigung im Sinn des § 7 Abs. 1 SGB IV und § 2 Nr. 9 SGB VI soll mit diesem Vertrag ausdrücklich nicht begründet werden. Der Auftragnehmer ist nicht weisungsgebunden und vollkommen frei in seiner ärztlichen Entscheidungsfindung.',
  },
  PARAGRAPHS: [
    {
      TITLE: '§ 1 - Leistungspflichten des Auftragnehmer',
      LISTING: [
        {
          TEXT: 'Der Auftraggeber beauftragt den Auftragnehmer im Rahmen seiner in § 4 Abs. 1 nachgewiesenen Qualifikation mit der selbständigen Erbringung des/der ihm auferlegten KV-Dienste(s) unter der Auftragsnummer { orderNumber } vom { start } Uhr bis { end } Uhr',
        },
        {
          TEXT: 'Die vom Auftragnehmer für den Auftraggeber zu erbringenden Dienstleistungen umfassen dabei alle im Rahmen des KV-Dienstes anfallenden ärztlichen und verwaltungstechnischen Aufgaben, insbesondere die eigenständige und eigenverantwortliche ärztliche Versorgung der den KV-Dienst in Anspruch nehmenden Patienten.',
        }
      ],
    },
    {
      TITLE: '§ 2 - Vertragsdurchführung',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer ist bei der Durchführung der KV-Dienste in seiner Berufsausübung frei und nicht den Weisungen des Auftraggebers unterworfen. Er führt den KV-Dienst in eigener Verantwortung nach bestem Wissen und Gewissen sowie den Regeln der ärztlichen Kunst aus.',
        },
        {
          TEXT: 'Der Auftragnehmer verpflichtet sich, die Interessen des Auftraggebers zu berücksichtigen und alle berufsrechtlichen und vertragsärztlichen Regelungen, die für die Durchführung des KV-Dienstes gelten, einzuhalten, insbesondere die Regelungen der für sein Einsatzgebiet gültigen Bereitschaftsdienstordnung (insbesondere die Maßgaben über Dienstantritt, Dienstdurchführung, sowie –beendigung) und das Wirtschaftlichkeitsgebot des § 12 Abs. 1 SGB V. Er hat weiterhin fachliche und organisatorische Vorgaben des Auftraggebers zu beachten, die zur ordnungsgemäßen Erfüllung des KV-Dienstes erforderlich sind.',
        },
        {
          TEXT: 'Der Auftragnehmer erbringt die Dienstleistungen grundsätzlich mit von ihm zu stellenden Hilfsmitteln, Werkzeugen und Materialien. Soweit der Auftragnehmer für die Durchführung des KV-Dienstes aufgrund von vertragsärztlichen Regelungen auf Hilfsmittel, Werkzeuge und Materialien des Auftraggebers angewiesen ist (z.B. Rezepte, Verordnungen, Abhalten eines Notdienstes in den Räumlichkeiten des Auftragnehmers etc.) stellt der Auftraggeber diese dem Auftragnehmer unentgeltlich durch einen Dritten zur Verfügung. Der Auftraggeber hat diese Verpflichtung erfüllt, sobald die notwendigen Hilfsmittel, Werkzeuge und Materialien durch einen Dritten bzw. dem Auftragnehmer überlassen wurden. Soweit der KV-Dienst nicht in den Räumlichkeiten des Auftraggebers durchzuführen ist, kann der Auftragnehmer vom Auftraggeber nur die Herausgabe der benötigten Materialien vom Dritten verlangen.',
        },
        {
          TEXT: 'Der Auftragnehmer verwendet seine eigene Dienstkleidung.',
        }
      ],
    },
    {
      TITLE: '§ 3 - Verhinderung des Auftragnehmers',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer ist verpflichtet, den Auftraggeber bzw. die im Rahmen der Vermittlung vertretungsberechtigte arztpool24 GmbH ohne schuldhaftes Zögern unverzüglich darüber zu informieren, wenn er die Dienstleistung nicht erbringen kann, gleich aus welchem Grund.',
        },
        {
          TEXT: 'Der Auftragnehmer haftet dem Auftraggeber nicht für eventuelle Schäden, die dem Auftraggeber durch den Ausfall entstehen, es sei denn er hat die Verhinderung vorsätzlich oder grob fahrlässig verschuldet.',
        }
      ],
    },
    {
      TITLE: '§ 4 - Nachweis für ärztliche Tätigkeit',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer stellt dem Auftraggeber zum Nachweis seiner Identität und Qualifizierung die im Folgenden genannten Unterlagen zur Verfügung:',
          SUB_LISTING: [
            'beglaubigte Kopie der Approbationsurkunde oder des Nachweises nach § 10 BÄO über die Erlaubnis zur Ausübung des ärztlichen Berufs',
            'soweit vorhanden weitere Qualifizierungsnachweise über Facharztausbildung, Ausbildung zum Notarzt etc.'
          ]
        },
        {
          TEXT: 'Der Nachweis gegenüber dem Auftraggeber gilt als erbracht, sobald der Auftragnehmer diese Unterlagen der arztpool24 GmbH vorlegt. Unabhängig hiervon ist der Auftraggeber jederzeit berechtigt, die Herausgabe dieser Unterlagen von der Firma arztpool24 GmbH zu verlangen, hierzu stimmt der Auftragnehmer schon jetzt ausdrücklich unwiderruflich zu.',
        },
      ],
    },
    {
      TITLE: '§ 5 - Nachweis über Berufshaftpflichtversicherung',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer verpflichtet sich, eine Berufshaftpflichtversicherung für die hier vertraglich vereinbarte Vertretungstätigkeit im KV-Dienst abzuschließen bzw. eine schon bestehende ggf., um diese Tätigkeiten zu erweitern. Die Deckungssummen dürfen mindestens 5.000.000€ pauschal für Personen- und Sachschäden sowie mindestens 500.000€ für Vermögensschäden nicht unterschreiten. Der Auftragnehmer versichert dem Auftraggeber, dass die Höchstersatzleistung der Haftpflichtversicherung bisher nicht über die genannte Deckungssumme in Anspruch genommen wurde.',
        },
        {
          TEXT: '§ 4 Abs. 2 gilt entsprechend.',
        },
      ]
    },
    {
      TITLE: '§ 6 - Prüfverfahren wegen Sozialversicherungspflicht',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer verpflichtet sich, dem Auftraggeber im Fall der Durchführung eines Prüfverfahrens durch die Träger der Kranken- und Rentenversicherung die notwendigen Unterlagen und Belege zur Verfügung zu stellen, die über seine sonstigen Beschäftigungsverhältnisse entsprechend den Anforderungen des Prüfverfahrens Auskunft geben.',
        },
        {
          TEXT: 'Der Auftraggeber kann die Vorlage geeigneter Nachweise verlangen, ob und in welchem Umfang eine private Rentenversicherung zur Absicherung bei Alter und Invalidität und eine Krankenversicherung bestehen sowie sich schriftlich versichern lassen, dass eine ausreichende soziale Absicherung besteht. Als ausreichende Krankenversicherung gilt auch die freiwillige Versicherung in einer gesetzlichen Krankenversicherung.',
        },
      ]
    },
    {
      TITLE: '§ 7 - Honorar',
      LISTING: [
        {
          TEXT: 'Für den in §1, Abs. 1 definierten Dienst erhält der Auftragnehmer vom Auftragnehmer ein Honorar in Höhe von { totalSum }, das entspricht { duration } x { pricePerHour }€ pro Stunde Honorar.',
        },
        {
          TEXT: 'Die Extrapauschale wird in Abhängigkeit des Patientenaufkommens, nur bei Hausbesuchsdiensten/Fahrdiensten berechnet, auf Grundlage der aktuellen Preisliste von arztpool24 GmbH.',
        },
        {
          TEXT: 'Führt der Auftragnehmer den KV-Dienst nicht durch, gleich aus welchem Grund, wird der Auftraggeber von seiner Vergütungspflicht befreit.',
        },
        {
          TEXT: 'Der Auftragnehmer erstellt eine entsprechende Rechnung über das Stunden- sowie angefallene Extrahonorar nach Durchführung des KV-Dienstes mit einem entsprechenden Tätigkeitsnachweis. Das Honorar ist vom Auftraggeber innerhalb von 14 Tagen ohne Abzug zu begleichen.',
        },
      ]
    },
    {
      TITLE: '§ 8 - Stornierung des Vertrages durch den Auftraggeber',
      LISTING: [
        {
          TEXT: 'Für den in §1, Abs. 1 definierten Dienst erhält der Auftragnehmer vom Auftragnehmer ein Honorar in Höhe von { totalSum }, das entspricht { pricePerHour }€ pro Stunde.',
        },
        {
          TEXT: 'Die Extrapauschale wird in Abhängigkeit des Patientenaufkommens, nur bei Hausbesuchsdiensten/Fahrdiensten berechnet, auf Grundlage der aktuellen Preisliste von arztpool24 GmbH.',
        },
        {
          TEXT: 'Führt der Auftragnehmer den KV-Dienst nicht durch, gleich aus welchem Grund, wird der Auftraggeber von seiner Vergütungspflicht befreit.',
        },
        {
          TEXT: 'Der Auftragnehmer erstellt eine entsprechende Rechnung über das Stunden- sowie angefallene Extrahonorar nach Durchführung des KV-Dienstes mit einem entsprechenden Tätigkeitsnachweis. Das Honorar ist vom Auftraggeber innerhalb von 14 Tagen ohne Abzug zu begleichen.',
        }
      ],
    },
    {
      TITLE: '§ 9 - Haftung',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer haftet dem Auftraggeber für Schäden, die er bei Durchführung des KV-Dienstes schuldhaft verursacht hat. Die Haftung beschränkt sich dabei auf vorsätzliches und grob fahrlässiges Verhalten.',
        },
        {
          TEXT: 'Der Auftragnehmer haftet dem Auftraggeber auch für Ansprüche von Dritten, die diese gegenüber dem Auftraggeber aufgrund einer rechtskräftig festgestellten Pflichtverletzung aus dem Behandlungsvertrag zwischen dem Auftraggeber und dem jeweiligen Dritten geltend machen und die auf einer Pflichtverletzung des Auftragnehmers beruhen. Der Auftragnehmer verpflichtet sich insoweit auch, den Auftraggeber bei einer Inanspruchnahme im Innenverhältnis freizustellen.',
        },
        {
          TEXT: 'Der Auftraggeber verpflichtet sich im Fall seiner oder der Inanspruchnahme des Auftragnehmers aus vertraglichen oder deliktischen Ansprüchen Dritter den Auftragnehmer sofort darüber in Kenntnis zu setzen und diesem ohne schuldhaftes Zögern alle notwendigen Unterlagen, insbesondere die vollständige Patientendokumentation des jeweiligen Dritten, zur Abwehr des Anspruches zur Verfügung zu stellen, auch wenn der Vertrag bereits beendet wurde.',
        },
        {
          TEXT: 'Der Auftragnehmer verpflichtet sich, dem Auftraggeber Mitteilung zu machen, sobald ihm haftungsrelevante Tatsachen im Rahmen seiner vertraglichen Tätigkeit bekannt werden.',
        }
      ]
    },
    {
      TITLE: '§ 10 - Geheimhaltung',
      LISTING: [
        {
          TEXT: 'Die Parteien verpflichten sich über die Regelung dieses Vertrages auch über das Vertragsende hinaus Stillschweigen zu bewahren.',
        },
        {
          TEXT: 'Der Auftragnehmer ist verpflichtet über alle vertraulichen kaufmännischen, technischen, organisatorischen, wirtschaftlichen und patientenbezogenen Informationen (ärztliche Schweigepflicht), die ihm im Zusammenhang mit seiner Tätigkeit für den Auftraggeber bekannt werden, Stillschweigen auch über das Vertragsende hinaus zu bewahren. Er ist befugt, die ihm anvertrauten personenbezogenen Daten im Rahmen der Zweckbestimmung des Auftraggebers zu verarbeiten. Die Verschwiegenheitspflicht gilt gegenüber jedermann, es sei denn, der Auftragnehmer ist gesetzlich zur Auskunft verpflichtet oder die Auskunft ist Behörden gegenüber geboten oder zur Wahrung von Rechtsansprüchen gegenüber Gerichten erforderlich.',
        },
        {
          TEXT:  'Die Verschwiegenheitsverpflichtung nach Abs. 1 und 2 gilt nicht gegenüber der Firma arztpool24 GmbH, soweit diese Informationen in diesem Sinne für die Vertragsabwicklung zwischen dem Auftragnehmer und Auftraggeber gem. § 6 Abs. 5 bzw. für die ordnungsgemäße Erfüllung des zwischen dem Auftraggeber und der Firma arztpool24 GmbH bestehenden Vertragsverhältnisses benötigt.'
        }
      ]
    },
    {
      TITLE: '§ 11 - Schlussbestimmungen',
      LISTING: [
        {
          TEXT: 'Mündliche Nebenabreden wurden nicht getroffen. Änderungen und Ergänzungen dieses Vertrages bedürfen der Schriftform. Dies gilt auch für eine Abänderung dieser Schriftformklausel.',
        },
        {
          TEXT: 'Sollte eine Bestimmung dieses Vertrages aus irgendeinem Grunde rechtsunwirksam sein, so wird dadurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Die Parteien sind in diesem Fall verpflichtet, die unwirksame Bestimmung durch eine wirksame zu ersetzen, mit der nach Möglichkeit derselbe wirtschaftliche Erfolg erreicht wird. Entsprechendes gilt für Lücken des Vertrages.',
        }
      ]
    }
  ],
};
