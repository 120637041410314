export default {
  META: {
    TITLE: 'Datenschutz',
  },
  HEADING: 'Datenschutz',
  HEADING_SUB: 'Allgemeiner Hinweis und Pflichtinformationen',
  RESPONSIBLE_BODY: {
    HEADING: 'Benennung der verantwortlichen Stelle',
    TEXT: [
      'Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:',
      'arztpool24 GmbH',
      'Kurfürstendamm 194',
      '10707 Berlin',
      'Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der  Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).',
    ],
  },
  REVOCATION: {
    HEADING: 'Widerruf Ihrer Einwilligung zur Datenverarbeitung',
    TEXT: 'Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein  Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.',
  },
  COMPLAINT: {
    HEADING: 'Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde',
    TEXT: [
      'Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist  der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet.  Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:',
      'https://www.bfdi.bund.de/',
    ],
  },
  TRANSFERABILITY: {
    HEADING: 'Recht auf Datenübertragbarkeit',
    TEXT: ' Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.',
  },
  INFORMATION: {
    HEADING: 'Recht auf Auskunft, Berichtigung, Sperrung, Löschung',
    TEXT: 'Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit  über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.',
  },
  ENCRYPTION: {
    HEADING: 'SSL- bzw. TLS-Verschlüsselung',
    TEXT: 'Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als  Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die  Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der "https://" Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.',
  },
  PROTECTION_OFFICER: {
    HEADING: 'Datenschutzbeauftragter',
    TEXT: [
      'Wir haben einen Datenschutzbeauftragten bestellt.',
      'Felicitas Kneip',
      'Telefon: 030 – 20 62 50 10',
      'E-Mail:',
      "info{'@'}e-datenschutz.de",
    ],
  },
  LOG_FILES: {
    HEADING: 'Server-Log-Dateien',
    TEXT: [
      'In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr  Browser automatisch an uns übermittelt. Dies sind:',
      'Browsertyp und Browserversion',
      'Verwendetes Betriebssystem',
      'Referrer URL',
      'Hostname des zugreifenden Rechners',
      'Uhrzeit der Serveranfrage',
      'IP-Adresse',
      'Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.',
    ],
  },
  CONTACT: {
    HEADING: 'Kontaktformular',
    TEXT: [
      'Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereit zu stehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.',
      'Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.',
      'Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.',
    ],
  },
  Microsoft_Advertising: {
    HEADING: 'Microsoft Advertising',
    TEXT: [
      'Der Websitebetreiber verwendet Microsoft Advertising. Microsoft Advertising ist ein Online- Werbeprogramm der Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA. Microsoft Advertising ermöglicht es uns Werbeanzeigen in der Bing-Suchmaschine oder auf Drittwebseiten auszuspielen, wenn der Nutzer bestimmte Suchbegriffe bei Bing eingibt (Keyword-Targeting). Ferner können zielgerichtete Werbeanzeigen anhand der bei Microsoft vorhandenen Nutzerdaten (z. B. Standortdaten und Interessen) ausgespielt werden (Zielgruppen-Targeting). Wir als Websitebetreiber können diese Daten quantitativ auswerten, indem wir beispielsweise analysieren, welche Suchbegriffe zur Ausspielung unserer Werbeanzeigen geführt haben und wie viele Anzeigen zu entsprechenden Klicks geführt haben.',
      'Wir nutzen auf dieser Seite die universelle Ereignisnachverfolgung (UET) von Microsoft Advertising. Hierbei werden pseudonymisierte Daten erfasst, um nachzuverfolgen, welche Aktionen Sie auf unseren Webseiten durchführen, nachdem Sie auf eine Werbeanzeige bei Microsoft Advertising geklickt haben. Hierbei erfasst UET Ihre IP-Adresse (anonymisiert), Gerätekennungen, Informationen über Geräte- und Browsereinstellungen, Microsoft Click ID (wird in Cookie gespeichert), Verweildauer auf der Website, welche Bereiche der Website abgerufen wurden, über welche Anzeige Sie auf die Website gelangt sind und geklickte geklicktes Keyword.',
      'Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar.',
      'Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:',
      'https://learn.microsoft.com/de-de/compliance/regulatory/offering-eu-model-clauses',
      'Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:',
      'https://www.dataprivacyframework.gov/s/participant-search/participantdetail?contact=true&id=a2zt0000000KzNaAAK&status=Active',
    ],
  },
  GOOGLE_ANALYTICS: {
    HEADING: 'Google Analytics',
    TEXT: [
      'Unsere Website verwendet Funktionen des Webanalysedienstes Google Analytics. Anbieter des Webanalysedienstes ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.',
      'Google Analytics verwendet „Cookies.“ Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert und eine Analyse der Website-Benutzung ermöglichen. Mittels Cookies erzeugte Informationen über Ihre Benutzung unserer Website werden an einen Server von Google übermittelt und dort gespeichert. Server-Standort ist im Regelfall die USA.',
      'Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und ggf. auch Werbung zu optimieren.',
      'IP-Anonymisierung',
      'Wir setzen Google Analytics in Verbindung mit der Funktion IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in denen Google die volle IP-Adresse an einen Server in den USA überträgt und dort kürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Es findet keine Zusammenführung der von Google Analytics übermittelten IP-Adresse mit anderen Daten von Google statt.',
      'Browser Plugins',
      'Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer Website könnten dadurch jedoch eingeschränkt werden. Ebenso können Sie die Erfassung von Daten bezüglich Ihrer Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt anschließender Verarbeitung durch Google unterbinden. Dies ist möglich, indem Sie das über folgenden Link erreichbare Browser-Plugin herunterladen und installieren:',
      'https://tools.google.com/',
      'Widerspruch gegen die Datenerfassung',
      'Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen  unserer Website verhindert: Google Analytics deaktivieren. Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:',
      'https://support.google.com/',
      'Auftragsverarbeitung',
      'Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben wir mit Google einen Vertrag über die Auftragsverarbeitung abgeschlossen.',
      'Demografische Merkmale bei Google Analytics',
      'Unsere Website verwendet die Funktion “demografische Merkmale” von Google Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Eine Zuordnung der Daten zu einer bestimmten Person ist nicht möglich. Sie können diese Funktion jederzeit deaktivieren. Dies ist über die Anzeigeneinstellungen in Ihrem Google-Konto möglich oder indem Sie die Erfassung Ihrer Daten durch Google Analytics, wie im Punkt “Widerspruch gegen die  Datenerfassung” erläutert, generell untersagen.',
    ],
  },
};
